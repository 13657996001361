<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Accesos</h4>
            <div class="small text-muted">Administración de accesos</div>
          </b-col>

          <b-col sm="5">
            <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Acceso">
              <i class="fa fa-plus"></i> Agregar
            </b-button>                       

            <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" class="d-none d-md-block" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>            
          </b-col>  
        </b-row>
      </b-card>
      
      <b-card header-tag="header" footer-tag="footer">
        <b-row v-if="table.mostrarFiltros">
          <b-col sm="4">
            <b-form-group>
              <b-input-group>
                <b-form-input type="text" size="sm" placeholder="Ingrese su busqueda" v-model="table.filter"></b-form-input>
                <b-input-group-prepend>
                  <b-button variant="dark" size="sm" :disabled="!table.filter" @click="table.filter = ''">
                    <i class="fa fa-filter"></i> Filtrar
                  </b-button>
                </b-input-group-prepend>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-table class="mb-0"
                    responsive="sm"
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="table.items"
                    :fields="table.fields"
                    :filter="table.filter"
                    :current-page="table.currentPage"
                    :per-page="table.perPage"
                    selectable
                    select-mode="single"
                    @row-selected="onRowSelected"                    
                    v-if="table.items.length">                       

                <template v-slot:row-details="row">
                  <b-card>
                    <b-row>
                      <b-col md="12">
                        <table class="table table-hover table-sm">
                          <tbody>
                            <tr>
                              <td class="access-information-title-table-custom align-middle">Usuario</td>
                              <td class="access-information-value-table-custom align-middle">
                                {{row.item.user}}
                              </td>
                            </tr>
                            <tr>
                              <td class="access-information-title-table-custom align-middle">Contraseña</td>
                              <td class="access-information-value-table-custom align-middle">
                                {{row.item.pass}}
                              </td>
                            </tr>                                   
                            <tr>
                              <td class="access-information-title-table-custom align-middle">Observaciones</td>
                              <td class="access-information-value-table-custom align-middle">
                                {{row.item.observations}}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </b-col>
                    </b-row>
                  </b-card>
                </template>                
                
                <template v-slot:cell(id)="data">
                  <b>{{data.item.id}}</b>
                </template> 

               <template v-slot:cell(name)="data">
                  <b>{{data.item.name}}</b>
                </template> 

                <template v-slot:cell(url)="data">
                  <b-link :href="data.item.url" target="_blank">
                    Ver Login Servicio
                    <i class="fa fa-angle-double-right"></i>
                  </b-link>
                </template> 
        
                <template v-slot:cell(observations)="data">
                  <div v-if="data.item.observations">
                    <div v-if="data.item.observations.length >50" :title="data.item.observations">
                      <b-icon icon="card-text" class="mr-1" style="font-size:17px;"></b-icon>
                      {{data.item.observations.substr(0, 50)}}
                    </div>
                    <div v-else>                      
                      {{data.item.observations}}
                    </div>
                  </div>
                </template>          
                
                <template v-slot:cell(f_action)="data">
                  <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                    <b-dropdown-item @click="edit(data.item)">
                      <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                    </b-dropdown-item>
                    <b-dropdown-item @click="remove(data.item)">
                      <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>
          <b-col>
            <nav>
              <b-pagination class="pull-right mb-0"
                            size="sm"
                            pills=""
                            :total-rows="getRowCount(table.items)"
                            :per-page="table.perPage"
                            v-model="table.currentPage" />
            </nav>
          </b-col>            
        </b-row>
      </b-card>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"              
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>
                
        <b-row>
          <b-col md="12">
            <b-form-group label="Nombre">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.form.name"
                            required
                            placeholder="Ingresar el nombre del acceso">
              </b-form-input>
            </b-form-group>                                              
          </b-col>     

          <b-col md="12">
            <b-form-group label="URL">
              <b-form-input type="url"
                            size="sm"
                            v-model="crud.form.url"
                            required
                            placeholder="Ingresar el link para login del servicio">
              </b-form-input>
            </b-form-group>                                              
          </b-col> 
          <b-col md="6">
            <b-form-group label="Usuario">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.form.user"
                            required
                            placeholder="Ingresar el nombre de usuario">
              </b-form-input>
            </b-form-group>                                              
          </b-col>        
          <b-col md="6">
            <b-form-group label="Contraseña">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.form.pass"
                            required
                            placeholder="Ingresar la contraseña">
              </b-form-input>
            </b-form-group>                                              
          </b-col>   
          <b-col md="12">                
            <b-form-group label="Observaciones">
              <b-form-textarea                                
                v-model="crud.form.observations"
                size="sm"
                placeholder="Ingresar las observaciones"
              ></b-form-textarea>
            </b-form-group>      
          </b-col>                           
        </b-row>        
        
        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal> 

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import Imagen from '@/components/inc/image/image' 
  import moment from 'moment'
  
  export default {     
    data: () => {
      return {      
        access: {
          module_id: Modules.ACCESOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crud',
          elements: {}
        }, 
        table : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true},            
            {key: 'name', label: 'Nombre', sortable: true},            
            {key: 'url', label: 'URL'},                    
            {key: 'observations', label: 'Observaciones'},
            {key: 'f_action', label:''},
          ],
          filter: null,
          mostrarFiltros: false,
          currentPage: 1,
          perPage: 50,
        },
        crud: {
          form: {
            id: 0,
            name: '',
            url: '',
            user: '',            
            pass: '',                 
            observations: ''
          },          
        },
        modal: {
          form: {
            active: false,
            title: ''
          },
        },
        arr: {
          access: []
        }        
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {
      this.load()
    },
    methods: {
      getRowCount (items) {
        return items.length
      },
      onRowSelected(item) {      
        this.arr.access.forEach((element, index) => {
          if(item.length){
            if(element.id == item[0].id) {
              this.openDetail(index)
            }
          }
        }) 
      },     
      openDetail(index) {                                
        this.table.rowSelected = index
        this.load()
      },        
      load() {
        var result = serviceAPI.obtenerAccess()

        result.then((response) => {
          var data = response.data
          this.table.items = data
          this.arr.access = data

          if(this.table.rowSelected!=null) {
            this.table.items[this.table.rowSelected]._showDetails = true
          }           
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });        
      },
      add() {
        this.crud.form.id = 0
        this.crud.form.name = ''
        this.crud.form.url = ''
        this.crud.form.user = ''
        this.crud.form.pass = ''              
        this.crud.form.observations = ''

        this.modal.form.title = "Nuevo Acceso"
        this.modal.form.active = true
      },
      edit(item) {
        this.crud.form.id = item.id        
        this.crud.form.name = item.name
        this.crud.form.url = item.url
        this.crud.form.user = item.user
        this.crud.form.pass = item.pass                         
        this.crud.form.observations = item.observations
        
        this.modal.form.title = "Editar Acceso"
        this.modal.form.active = true
      },
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.form.id + ') ' + this.crud.form.name + '?', {
          title: 'Borrar Acceso',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarAccess(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.load()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {
        let loader = this.$loading.show();

        if (this.crud.form.id) {
          var result = serviceAPI.editarAccess(this.crud.form);
        } else {
          var result = serviceAPI.agregarAccess(this.crud.form);
        }

        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.load()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
    }    
  }
</script>
<style>
  
  .access-information-title-table-custom {
    color: #fff;
    background-color: #2f353a;
    border-color: #40484f;
    font-weight: 700;
    padding-right: 10px;
    width: 30%;
    text-align: right
  }
  .access-information-value-table-custom {
    padding-left: 5px;
  }  
  .access-informations-title-banners {
    font-weight: bold;
    font-size: 17px;
    margin-bottom: 5px;
  }  
  .access-informations-banners {
    height: 80px;
    margin-right: 5px;
    margin-bottom: 5px;
  }
</style>